<template>
  <div class="plugin-setup">
    <div class="plugin-setup__container">
      <ElementCard
        shadow="none"
        class="plugin-setup__card"
      >
        <h1>Hub3D Plugin 🔥</h1>
        <p>By far the most comfortable way to design with Stitch 3D products</p>
        <a :href="pluginDownloadUrl">
          <ElementButton
            type="primary"
            plain
            @click="handleDownloadClick"
          >Download Plugin</ElementButton>
        </a>
      </ElementCard>

      <ElementCard
        shadow="none"
        class="plugin-setup__card"
      >
        <h1>Smooth Setup 🔧</h1>

        <ol class="plugin-setup__list-main">
          <li>
            Download and unpack
            <ul class="plugin-setup__list-sub">
              <li>Download the Hub3D Plugin</li>
              <li>
                Move it to a safe location where you won't delete it and is
                <strong>NOT</strong> in a shared folder (<strong>don’t forget where!</strong>)
              </li>
              <li>Unzip it!</li>
            </ul>
          </li>
          <li>
            Set up Browzwear
            <ul class="plugin-setup__list-sub">
              <li>Launch Browzwear</li>
              <li>
                On Mac go to
                <strong>Lotta/VStitcher → Preferences → Plugins</strong>
              </li>
              <li>
                For Windows go to
                <strong>Edit → Preferences → Plugins</strong>
              </li>
              <li>Select <strong>+</strong></li>
              <li>Navigate to where you previously unzipped the plugin</li>
              <li>Finally <strong>select the folder</strong> you unzipped</li>
            </ul>
          </li>
          <li>
            You're all set!
            <ul class="plugin-setup__list-sub">
              <li>
                Go to <strong>Plugins → Hub3D Hub</strong> in VStitcher or Lotta
              </li>
              <li>Enter your Hub3D Hub credentials</li>
              <li>
                Click the download icon to sync a fabric or trim to your
                Browzwear garment file
              </li>
            </ul>
          </li>
        </ol>
      </ElementCard>
    </div>
  </div>
</template>

<script>
import { TRACKER_OBJECTS, TRACKER_EVENTS } from '@/constants/tracker'

export default {
  name: 'PluginSetup',

  data: () => ({
    pluginDownloadUrl: process.env.VUE_APP_STITCH_PLUGIN_DOWNLOAD_URL
  }),

  methods: {
    /**
     *
     */
    handleDownloadClick () {
      this.$tracking.trackEvent({
        object: TRACKER_OBJECTS.PLUGIN,
        action: TRACKER_EVENTS.DOWNLOADED,
        category: null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.plugin-setup {
  height: 100%;
}

.plugin-setup__container {
  height: 100%;
  padding: spacing(6) spacing(4);
  overflow-y: scroll;
}

.plugin-setup__container::-webkit-scrollbar {
  display: none;
}

.plugin-setup__card {
  margin-bottom: spacing(4);
  padding: spacing(2);

  h1 {
    margin-bottom: spacing(1);
  }

  p {
    margin-bottom: spacing(2);
  }

  .plugin-setup__list-main {
    @include text-title-small;
  }

  .plugin-setup__list-sub {
    @include text-body;

    margin: spacing(1) 0;
    padding-left: spacing(2);
    list-style: none;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
